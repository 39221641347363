<template>
  <div class="p-30">
    <div style="margin-bottom: 20px">
      <h5 class="font-bold">{{ $t("label.insuranceCategory") }}</h5>
      <!-- Insurance Categories -->
      <div>
        <a-radio-group
          v-model="value"
          default-value="cv_insurance"
          @change="changeCategory"
        >
          <a-radio-button
            v-for="item in insurance_category"
            :key="item.id"
            class="insurance-category"
            :value="item.value"
          >
            <div style="text-align: center">
              <img
                slot="cover"
                alt="insurance"
                :src="item.image"
                style="margin-top: 5px; max-width: 200px; max-height: 100px"
              />
              <a-card-meta class="mt-3" :title="item.title" />
            </div>
            <a-icon
              v-if="value === item.value"
              type="check-circle"
              theme="twoTone"
              class="check-icon"
            ></a-icon>
          </a-radio-button>
        </a-radio-group>
      </div>
      <!-- Insurance Categories -->
    </div>
    <div style="margin-bottom: 20px">
      <h5 class="font-bold">{{ $t("label.insurancePackage") }}</h5>
      <div>
        <a-radio-group
          v-for="item in insurance_package"
          :key="item.id"
          v-model="price"
          :default-value="item.price"
          @change="changePackage"
        >
          <a-radio-button class="insurance-package" :value="item.price">
            <div>
              <a-card-meta class="mt-3" :title="item.title"> </a-card-meta>
              <a-statistic
                title=""
                prefix="₭"
                :precision="2"
                :value="item.price"
                valueStyle="font-size:14px"
              />
            </div>
            <a-icon
              v-if="price === item.price"
              type="check-circle"
              theme="twoTone"
              class="check-icon"
            ></a-icon>
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <h5 class="font-bold">{{ $t("label.listMember") }}</h5>
    <div
      class="flex"
      style="
        margin-bottom: 10px;
        /* color: #008000; */
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        /* width: auto; */
        background-color: #008000;
        border-radius: 10px;
      "
    >
      <div style="margin-bottom: 20px; padding: 10px">
        <a-row type="flex">
          <a-col :span="6" :xs="12" :lg="8">
            <a-checkbox
              style="font-size: 16px; font-weight: bold"
              :checked="checked"
              @change="onChange"
            >
              {{ $t("label.includeMe") }}
            </a-checkbox>
          </a-col>
        </a-row>
      </div>
      <!-- <hr /> -->
      <div>
        <a-row type="flex" :gutter="24">
          <a-col :span="24" :md="12" class="mb-24">
            <CardMemberList
              :user="checked ? userData : []"
              :member="memberData"
              @remove="removeMember"
            ></CardMemberList>
            <!-- <CardMemberList
              :data="checked ? userData : [] && memberData ? memberData : []"
              :member="memberData"
              @remove="removeMember"
            ></CardMemberList> -->
          </a-col>
        </a-row>
      </div>
      <a-row type="flex">
        <a-col :span="6" :xs="12" :lg="8">
          <a-button
            icon="user-add"
            style="
              background-color: #008000;
              color: white;
              margin: 2px 0px 10px 10px;
            "
            @click="isModalVisible = true"
            >{{ $t("button.addMember") }}</a-button
          >
          <AddMember
            :visible="isModalVisible"
            @close="handleModalClose"
            @form-member="handleAddMember"
          />
        </a-col>
        <a-col :span="6" :xs="12" :lg="8">
          <a-button
            icon="shopping-cart"
            style="
              background-color: #008000;
              color: white;
              margin: 2px 0px 10px 10x;
            "
            @click="showBuy"
          >
            {{ $t("button.buyNow") }}
          </a-button>
          <a-modal
            v-model="buy"
            :dialog-style="{ top: '20px' }"
            :title="$t('form.termCondition')"
            :okText="$t('button.agree')"
            :cancelText="$t('button.cancel')"
            :width="800"
            @ok="greeCondition"
          >
            <Policy />
          </a-modal>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import CardMemberList from "../components/Cards/CardMemberList";
import AddMember from "../components/Cards/CardAddMember.vue";
import Policy from "../components/Policy/Policy.vue";

const insurance_category = [
  {
    id: 1,
    title: "CV Insurance",
    image: "images/cv-insurance.webp",
    value: "cv_insurance",
  },
  {
    id: 2,
    title: "Health Insurance",
    image: "images/health-insurance.webp",
    value: "health_insurance",
  },
];
const insurance_package = [
  {
    id: 1,
    title: "CV1 (ປະກັນໄພປີ) / 12 Months",
    price: 500000,
  },
  {
    id: 2,
    title: "H Insurance / 24 Months",
    price: 1000000,
  },
];

export default {
  name: "BuyInsurance",
  components: {
    CardMemberList,
    AddMember,
    Policy,
  },
  data() {
    const user = this.$store.state.auth.user;
    return {
      insurance_category,
      insurance_package,
      checked: true,
      value: "cv_insurance",
      price: Number,
      isModalVisible: false,
      buy: false,
      memberData: {},
      userData: [
        {
          id: user.id,
          name: user.first_name + " " + user.last_name,
          birthday: user.birthday,
          // avatar: "/images/header.jpg",
        },
      ],
    };
  },

  methods: {
    changeCategory(e) {
      console.log(`value = ${e.target.value}`);
    },
    changePackage(e) {
      console.log(`value = ${e.target.value}`);
      console.log("e=", e);
    },
    onChange(e) {
      this.checked = !this.checked;
      console.log("user :", this.userData);
    },
    handleModalClose() {
      this.isModalVisible = false;
    },
    removeMember() {
      this.memberData = {};
    },
    handleAddMember(member) {
      this.memberData = member;
    },
    showBuy() {
      this.buy = true;
    },
    greeCondition() {
      this.buy = false;
      return this.$router.push(`/payment/202304-00005`);
    },
  },
};
</script>
<style lang="scss" scoped>
.insurance-category {
  width: 240px;
  height: 150px;
  margin: 15px 15px 0 0;
  transition: box-shadow 0.3s ease;
  position: relative;
  left: 0;
  transition: left 0.3s;

  .check-icon {
    position: absolute;
    top: -10px;
    right: -8px;
    font-size: 24px;
  }
}
.insurance-category:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  left: 1px;
}
.insurance-package {
  width: 240px;
  height: 70px;
  margin: 10px 10px 0 0;
  .check-icon {
    position: absolute;
    top: -10px;
    right: -8px;
    font-size: 18px;
  }
}

.insurance-package:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  left: 1px;
}
</style>
