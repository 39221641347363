<template>
  <div>
    <Modal
      :visible="modalVisible"
      :dialog-style="{ top: '20px' }"
      :width="700"
      @cancel="handleModalClose"
      :title="$t('button.addMember')"
    >
      <template slot="footer">
        <a-button key="back" @click="handleModalClose">
          {{ $t("button.cancel") }}
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleAddMember"
        >
          {{ $t("button.submit") }}
        </a-button>
      </template>
      <a-form name="form">
        <a-row :gutter="24" style="margin-top: 20px">
          <a-col :xs="24" :md="12">
            <div class="my-10">
              <a-form-item :label="$t('form.firstName')">
                <a-input
                  v-model="member.firstName"
                  v-validate="'required|min:3|max:20'"
                  type="text"
                  class="form-control"
                  name="first_name"
                  data-vv-as="First Name"
                />
              </a-form-item>
              <div
                v-if="submitted && errors.has('first_name')"
                style="color: red"
              >
                {{ errors.first("first_name") }}
              </div>
            </div>
          </a-col>
          <a-col :xs="24" :md="12">
            <div class="my-10">
              <a-form-item :label="$t('form.lastName')">
                <a-input
                  v-model="member.lastName"
                  v-validate="'required|min:3|max:20'"
                  type="text"
                  class="form-control"
                  name="last_name"
                  data-vv-as="Last Name"
                />
              </a-form-item>
              <div
                v-if="submitted && errors.has('last_name')"
                style="color: red"
              >
                {{ errors.first("last_name") }}
              </div>
            </div></a-col
          >
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-item :label="$t('form.gender.title')">
              <a-select
                v-validate="'required'"
                placeholder="Select your gender"
                v-model="member.gender"
                data-vv-as="Gender"
                name="gender"
              >
                <a-select-option value="male">
                  {{ $t("form.gender.male") }}
                </a-select-option>
                <a-select-option value="female">
                  {{ $t("form.gender.female") }}</a-select-option
                >
                <a-select-option value="other">
                  {{ $t("form.gender.other") }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div style="color: red">
              {{ errors.first("gender") }}
            </div>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item :label="$t('form.birthday')">
              <a-date-picker
                format="DD/MM/YYYY"
                v-model="member.birthday"
                v-validate="'required'"
                name="birthday"
                data-vv-as="Date of Birth"
              />
            </a-form-item>
            <div style="color: red">
              {{ errors.first("birthday") }}
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-model-item :label="$t('form.passportNumber')">
              <a-input
                v-model="member.passport"
                v-validate="'required|min:5|max:20'"
                type="text"
                class="form-control"
                name="passport"
                data-vv-as="Passport"
            /></a-form-model-item>
            <div style="color: red">
              {{ errors.first("passport") }}
            </div>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-model-item :label="$t('form.relationship.title')">
              <a-select
                v-model="member.relationship"
                v-validate="'required'"
                placeholder="Select your relationship"
                data-vv-as="Relationship"
                name="relationship"
              >
                <a-select-option value="couple">
                  {{ $t("form.relationship.couple") }}
                </a-select-option>
                <a-select-option value="children">
                  {{ $t("form.relationship.children") }}
                </a-select-option>
                <a-select-option value="parents">
                  {{ $t("form.relationship.parents") }}
                </a-select-option>
                <a-select-option value="friend">
                  {{ $t("form.relationship.friend") }}
                </a-select-option>
                <a-select-option value="other">
                  {{ $t("form.relationship.other") }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <div style="color: red">
              {{ errors.first("relationship") }}
            </div>
          </a-col>
        </a-row>

        <h4>{{ $t("form.document") }}</h4>
        <a-row :gutter="24">
          <a-col :span="8">
            <p style="justify-content: center">{{ $t("form.passport") }}</p>
            <WidgetAddPhoto />
          </a-col>
          <a-col :span="8">
            <p>{{ $t("form.visa") }}</p>
            <WidgetAddPhoto />
          </a-col>
          <a-col :span="8">
            <p>{{ $t("form.other") }}</p>
            <WidgetAddPhoto />
          </a-col>
        </a-row>
      </a-form>
    </Modal>
  </div>
</template>
<script>
import { Modal } from "ant-design-vue";
import WidgetAddPhoto from "../Widgets/WidgetAddPhoto.vue";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  components: {
    WidgetAddPhoto,
    Modal,
  },
  props: {
    visible: Boolean,
    default: false,
  },
  data() {
    return {
      modalVisible: this.visible,
      other: "",
      message: "",
      submitted: false,
      loading: false,
      member: {
        firstName: "",
        lastName: "",
        passport: "",
        gender: "",
        relationship: "",
        birthday: "",
      },
    };
  },

  watch: {
    visible(newValue) {
      this.modalVisible = newValue;
    },
  },

  methods: {
    handleModalClose() {
      this.modalVisible = false;
      this.$emit("close");
    },
    handleAddMember() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.loading = true;
          this.$emit("form-member", {
            member: this.member,
          });

          setTimeout(() => {
            this.loading = false;
            this.member = [];
            this.$notification.open({
              message: "Add member successfully!",
              icon: <a-icon type="check" style="color: #008000" />,
              duration: 3,
            });
          }, 1000);
          this.handleModalClose();
        }
      });
    },
  },
};
</script>
